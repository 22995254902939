











































import Vue from 'vue'
// import moment from 'moment'
import request from '@/utils/request'

export default Vue.extend({
  data() {
    return {
        isShow: false,
        state: null,
        monthStart: new Date(2010, 0, 1),
        monthEnd: new Date(),

        endMonthStartRange: null,
        endMonthEndRange: null,
        startDayRange: null,
        endDayRange: null,
        obj: {
            comment: '暂无驳回理由',
            batchName: '',
            dateStart: '',
            dateEnd: '',
            datePick: '',
            summary: '',
            images: [],
            imagesUrl: [],
        },
        alertShow: false,
    }
  },
  async created() {
    // this.getData()
  },
  methods: {
    changeTab(id: any) {
      this.examineStatus = id
      this.pageNum = 1
      this.getData()
    },
    onLoad() {
      this.pageNum += 1
      this.getData()
    },
    getData() {
      this.loading = true
      const { pageNum, pageSize, batchName, examineStatus } = this
      const params = { pageNum, pageSize, batchName, examineStatus } as any
      // console.log(params)
      request.get('/api/tea/rest_tracing_source/page', params).then(({ list, total }) => {
        if (pageNum == 1) this.list = list
        else this.list = this.list.concat(list)
        this.total = total
        this.loading = false
      })
    },
    bindEnter() {
        this.$router.push(`/stateDetailEdit`)
    },
    view(item: any) {
        const data = { state: item.examineStatus, id: item.id }
        if (item.state == 3) {
            this.$router.push(`/stateDetailEwm?data=${JSON.stringify(data)}`)
      } else if (item.state == 0 || item.state == 2) {
            this.$router.push(`/stateDetailView?data=${JSON.stringify(data)}`)
      } else {
            this.$router.push(`/stateDetailEdit?data=${JSON.stringify(data)}`)
      }
    },
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
  },
})
